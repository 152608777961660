<!--提报记录：提报记录-->
<template>
  <div class="app-container">
    <!--页头-->
    <div class="page-container">
      <div class="section">
        <div class="control-box">
          <div class="cb-left">
            <h1 style="font-size: 20px;">{{ $t('Historical_Suspension_Reason') }}</h1>
          </div>
          <div class="cb-right">
            <el-button size="small" type="" icon="el-icon-back" @click="back">{{ $t('return') }}</el-button>
          </div>
        </div>
        <div class="table-box">
          <el-table
              :data="replacementData"
              border
              highlight-current-row
              style="width: 100%"
          >
            <el-table-column :label="$t('Person_Suspended')" align="center">
              <template slot-scope="scope">
                {{ scope.row.person }}
              </template>
            </el-table-column>
            <el-table-column :label="$t('Reason_Suspending')" align="center">
              <template slot-scope="scope">
                {{ scope.row.reason }}
              </template>
            </el-table-column>

            <el-table-column :label="$t('Suspension_Time')" align="center">
              <template slot-scope="scope">
                {{ scope.row.time }}
              </template>
            </el-table-column>
            <el-table-column :label="$t('Estimated_Start_Time')" align="center">
              <template slot-scope="scope">
                {{ scope.row.startDate }}
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import API from '@/api';

export default {
  name: "reportList",
  data() {
    return {
      replacementData: [],// 定期更换
    };
  },
  computed: {},
  created() {
    this.getList()
  },
  mounted() {
    // console.log(this.$store.state)
    // console.log(this.$store.state.userInfo.userId)
  },
  methods: {
    getList() {
      API.pendingList(this.$route.query.repairId).then(res => {
        this.replacementData = res.message.data
      })
    },
    back() {
      this.$router.go(-1)
    },
  },
};
</script>
<style scoped lang="scss">
.table-box {
  position: relative;
}

::v-deep .dialog-footer {
  text-align: right;
}

::v-deep .el-tabs__active-bar {
  height: 2px;
  background-color: #F3A100;
}

.control-item {

}

.control-btn {
  text-align: right;
  position: absolute;
  right: 0px;
  top: -0px;
  z-index: 9999;
}

.control-box1 {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  position: absolute;
  right: 21px;
  top: 127px;
  z-index: 999;
}

.btn {
  display: flex;
  justify-content: space-between;
  border: 1px solid #dcdfe6;
  width: 100%;
  border-radius: 4px;
  line-height: 1;
  height: 40px;
  padding: 12px 10px 0px 15px;
  color: #cccfd6;
  cursor: pointer;
}

.person {
  margin-bottom: 20px;
}

.history {
  text-align: right;
}

.record {
  margin-bottom: 0px;

  img {
    width: 15px;
    margin-right: 10px;
  }

  span {
    //display: inline-block;
    text-align: right;
    width: 100%;
    cursor: pointer;
  }
}

.pic-and-video-wrapper {
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .btn {
    width: 90px;
    height: 90px;
    margin-top: 10px;
    background-color: #f1f1f1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    border: 0;
    padding: 0;

    img {
      width: 20px;
      height: 20px;
    }

    span {
      margin-top: 5px;
      font-size: 13px;
      color: grey;
    }

    .closeVideo-btn {
      margin-top: 8px;
    }
  }
}

.preview {
  width: 90px;
  height: 90px;
  margin-top: 10px;
  margin-right: 10px;
  position: relative;

  img {
    position: absolute;
    width: 90px;
    height: 90px;
  }

  .van-btton {
    position: absolute;
    right: 0;
    font-size: 20px;
    //top: -15px;
    background: #fff;
    padding: 0;
    color: #f56c6c;
  }
}

.voice {
  width: 100%;
  height: 34px;
  border-radius: 17px;
  background-color: #f1f1f1;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 22px;
    height: 22px;
  }
}

.video-label {
  width: 100%;
  height: 34px;
  border-radius: 17px;
  background-color: lightgrey;
  margin-top: 20px;
  display: flex;
  align-items: center;
  padding: 0 10px;
  justify-content: space-between;
  font-size: 13px;
  color: grey;

  .close-btn {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 20px;
      height: 20px;
    }
  }
}

.item {
  padding: 8px 10px;

  img {
    width: 32px;
    margin-right: 10px;
  }
}

.item:hover {
  background-color: #FFFAED;
}

.list-item {
  display: flex;
  justify-content: space-between;
  color: #9F9F9F;
  font-size: 14px;

  .name {
    color: #3D3D3D;
    font-size: 16px;
    margin-bottom: 10px;
  }

  padding: 15px 0;
  border-bottom: 1px solid #F1F1F1;
}

.item-left {
  display: flex;

  img {
    width: 56px;
    margin-right: 15px;
  }


}

::v-deep .el-tabs__item {
  line-height: 1.5;
}
</style>

